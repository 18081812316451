import React from 'react';
import PropTypes from 'prop-types';
import useSnackbar from '@sprinx/react-mui-layout/useSnackbar';
import ApiCallProvider from '@sprinx/react-api-client/ApiCallProvider';
import ConnectServerRest from '@sprinx/react-api-client/ConnectServerRest';
// import ConnectServerMock from '@sprinx/react-api-client/ConnectServerMock';
// import mockData from './__mocks__/mockCollections';

// function Dev(props) {
//   return <ConnectServerMock {...props} mockData={mockData} clientCode='api' secureClientId='__mock__' />;
// }

function Prod(props) {
  return (
    <ConnectServerRest
      {...props}
      clientCode='api'
      hostUri={process.env.REACT_APP_API_URI}
      secureClientId={process.env.REACT_APP_AUTH_CLIENT_ID}
      // realtime
    />
  );
}

function AppConnectServers({ accessToken, children, registerOnAuthenticate, registerOnLogout }) {
  const [showMessage] = useSnackbar();

  // const isApi = process.env.NODE_ENV === 'production';
  // const isApi = true;

  return (
    <ApiCallProvider showMessage={showMessage}>
      <Prod
        accessToken={accessToken}
        registerOnAuthenticate={registerOnAuthenticate}
        registerOnLogout={registerOnLogout}
      >
        {children}
      </Prod>

      {/* <Dev>{children}</Dev> */}
    </ApiCallProvider>
  );
}

AppConnectServers.propTypes = {
  accessToken: PropTypes.string,
  children: PropTypes.node,
  registerOnAuthenticate: PropTypes.func,
  registerOnLogout: PropTypes.func,
};

AppConnectServers.defaultProps = {
  accessToken: undefined,
  children: null,
  registerOnAuthenticate: undefined,
  registerOnLogout: undefined,
};

export default AppConnectServers;
