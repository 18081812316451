import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import AppLayout from '@sprinx/react-mui-layout/LayoutProductionApp';
import CartAside from '../../cart/CartAside';
import useShoppingCurrency from '../useShoppingCurrency';

const useStyles = makeStyles(
  (theme) => ({
    root: {},
    content: {
      dicplay: 'flex',
      width: '100%',
    },
    contentCartOpen: {
      paddingRight: theme.spacing(37),
      width: '100%',
      [theme.breakpoints.down('sm')]: {
        paddingRight: 0,
      },
    },
  }),
  { name: 'SxVirtRepLayoutShopping' },
);

function LayoutShopping({ children, classes: pClasses, className, ...rest }) {
  const classes = useStyles({ classes: pClasses });
  const [cartOpen, setCartOpen] = React.useState(false);
  const currency = useShoppingCurrency();

  return (
    <AppLayout {...rest} className={clsx(classes.root, className)}>
      <div className={clsx(classes.cartContainer, classes.contentCartOpen)}>
        {children}
        <CartAside currency={currency} open={cartOpen} onChangeCartOpen={setCartOpen} />
      </div>
    </AppLayout>
  );
}

LayoutShopping.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.shape({
    actions: PropTypes.string,
    cartContainer: PropTypes.string,
    content: PropTypes.string,
    contentCartOpen: PropTypes.string,
    root: PropTypes.string,
  }),
  className: PropTypes.string,
};

LayoutShopping.defaultProps = {
  children: null,
  classes: undefined,
  className: undefined,
};

export default LayoutShopping;
