export const termsAndConditions = [
  {
    code: 'I-0',
    label: [
      { language: 'en', text: 'I-0 - hotovosť vodičovi' },
      { language: 'sk', text: 'I-0 - hotovosť vodičovi' },
    ],
    discountPercent: 0.02,
    ruleCode: 'delivery-conditions-discount-to-all-products',
  },
  {
    code: 'P-1',
    label: [
      // 6.99
      { language: 'en', text: 'P-1 - prevodom cez banku na druhý deň po dodaní tovaru' },
      { language: 'sk', text: 'P-1 - prevodom cez banku na druhý deň po dodaní tovaru' },
    ],
    discountPercent: 0.02,
    ruleCode: 'delivery-conditions-discount-to-all-products',
  },
  {
    code: 'P-14',
    label: [
      // 7.06
      { language: 'en', text: 'P-14 - prevodom 14 dní' },
      { language: 'sk', text: 'P-14 - prevodom 14 dní' },
    ],
    discountPercent: 0.01,
    ruleCode: 'delivery-conditions-discount-to-all-products',
  },
  {
    code: 'P-28',
    label: [
      { language: 'en', text: 'P-28 - prevodom 28 dní' },
      { language: 'sk', text: 'P-28 - prevodom 28 dní' },
    ],
    discountPercent: null,
    ruleCode: 'delivery-conditions-discount-to-all-products',
  },
  {
    code: 'P-7',
    label: [
      { language: 'en', text: 'P-7 - prevodom 7 dní' },
      { language: 'sk', text: 'P-7 - prevodom 7 dní' },
    ],
    discountPercent: 0.01,
    ruleCode: 'delivery-conditions-discount-to-all-products',
  },
];

export const pricingRulesMutator = (term) => (rule) => {
  if (term.discountPercent === null) return null;

  const [strategy, [, ...rest]] = rule.map;

  return {
    ...rule,
    map: [strategy, [term.discountPercent, ...rest]],
  };
};
