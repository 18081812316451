import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { pickHTMLProps } from 'pick-react-known-prop';
import useTranslate from '@sprinx/react-globalize/useTranslate';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import useEnableIndirectSubjectHandler from '@sprinx/react-shopping-cart/useEnableIndirectSubjectHandler';

const useStyles = makeStyles(
  (theme) => ({
    root: {
      padding: theme.spacing(1),
      backgroundColor: theme.palette.grey[300],
    },
    addToCart: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  }),
  { name: 'CartSubjectListItemIndirectMarketing' },
);

const replaceInStr = (str, w) =>
  str
    .split('###')
    .map((x, i) => (i % 2 === 0 ? x : `###${x}###`))
    .filter(Boolean)
    .map((x, i) => w[x] || <span key={i.toString()}>{x}</span>);

function CartSubjectListItemIndirectMarketing({ classes: pClasses, className, subject: pSubject, ...rest }) {
  const t = useTranslate();
  const classes = useStyles({ classes: pClasses });
  const enableIndirectSubject = useEnableIndirectSubjectHandler();

  const subject = { ...pSubject, unit: pSubject.unit || t('unitOfMeasure/pcs') };

  return (
    <div {...pickHTMLProps(rest)} className={clsx(classes.root, className)}>
      <Typography component='div' variant='body2'>
        {replaceInStr(t('shoppingCart/messages/giftClaim', { productName: '###P###', quantity: '###Q###' }), {
          '###P###': (
            <Typography color='primary' variant='subtitle2' component='span'>
              {t(subject.product.name)}
            </Typography>
          ),
          '###Q###': (
            <Typography color='primary' variant='subtitle2' component='span'>
              {`${subject.quantity} ${subject.unit || ''}`.trim()}
            </Typography>
          ),
        })}
      </Typography>
      <Button
        className={classes.addToCart}
        variant='outlined'
        size='small'
        onClick={() => enableIndirectSubject(subject.id)}
      >
        {t('shoppingCart/actions/addToCart')}
      </Button>
    </div>
  );
}

CartSubjectListItemIndirectMarketing.propTypes = {
  classes: PropTypes.shape({
    addToCart: PropTypes.string,
    root: PropTypes.string,
  }),
  className: PropTypes.string,
  subject: PropTypes.shape({
    id: PropTypes.string.isRequired,
    product: PropTypes.shape({
      name: PropTypes.array,
    }).isRequired,
    quantity: PropTypes.number,
    unit: PropTypes.string,
  }).isRequired,
};

CartSubjectListItemIndirectMarketing.defaultProps = {
  classes: undefined,
  className: undefined,
};

export default CartSubjectListItemIndirectMarketing;
