import React from 'react';
import PropTypes from 'prop-types';
// import useMediaQuery from '@material-ui/core/useMediaQuery';
import useLogout from '@sprinx/react-auth/useLogout';
import AppBarActionUserAvatar from '@sprinx/react-mui-layout/AppBarActionUserAvatar';
import AppBarActionLogout from '@sprinx/react-mui-layout/AppBarActionLogout';
import AppBarActionCart from '@sprinx/react-shopping-cart/AppBarActionCart';
// import AppBarActionGlobalSearch from '@sprinx/react-mui-layout/AppBarActionGlobalSearch';
// --------------------------------
// import LanguageSelectAction from './langs/LanguageSelect/LanguageSelectAction';

// const cond = (...cnds) => (v) => (cnds.find((x) => x[0](v)) || [null, () => undefined])[1](v);

// eslint-disable-next-line no-unused-vars
const AppBarActions = React.forwardRef(({ variant }, ref) => {
  const handleLogout = useLogout();
  // const match = useMediaQuery('(min-width:550px)');
  // const searchVariant = cond(
  //   [() => match && variant === 'none', () => 'standalone'],
  //   [() => variant === 'standalone', () => 'standalone'],
  //   [() => match && variant === 'menuItem', () => 'none'],
  //   [() => true, () => variant],
  // )();

  return (
    <>
      {/* <AppBarActionGlobalSearch variant={searchVariant} /> */}
      {/* <LanguageSelectAction variant={variant} /> */}
      <AppBarActionCart variant={variant} path='/cart/summary' />
      <AppBarActionUserAvatar variant={variant} onClick={() => {}} />
      <AppBarActionLogout variant={variant} onClick={handleLogout} />
    </>
  );
});

AppBarActions.displayName = 'AppBarActions';
AppBarActions.propTypes = {
  variant: PropTypes.oneOf(['none', 'menuItem', 'standalone']).isRequired,
};

export default AppBarActions;
