import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import useRedirect from '@sprinx/react-routes/useRedirect';
import CartAsidePreview from '@sprinx/react-shopping-cart/CartAsidePreview';
import CartTools from '../CartTools';
import AppCartAsideSubjectsListItemDirect from './AppCartAsideSubjectsListItemDirect';
import AppCartAsideSubjectsListItemIndirect from './AppCartAsideSubjectsListItemIndirect';

const useStyles = makeStyles(
  (theme) => ({
    root: {},
    actions: {
      flexGrow: 0,
      width: '100%',
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      borderBottomWidth: 1,
      borderBottomColor: theme.palette.divider,
      borderBottomStyle: 'solid',
    },
  }),
  { name: 'CartAside' },
);

function CartAside({ classes: pClasses, className, currency, onChangeCartOpen, open, ...rest }) {
  const classes = useStyles({ classes: pClasses });
  const setRedirect = useRedirect();

  return (
    <CartAsidePreview
      {...rest}
      className={clsx(classes.root, className)}
      actions={<CartTools />}
      onChangeCartOpen={onChangeCartOpen}
      open={open}
      currency={currency}
      onOrder={() => setRedirect('/cart/summary')}
      CartSubjectsListItemDirectComponent={AppCartAsideSubjectsListItemDirect}
      CartSubjectsListItemIndirectComponent={AppCartAsideSubjectsListItemIndirect}
    />
  );
}

CartAside.propTypes = {
  classes: PropTypes.shape({
    actions: PropTypes.string,
    root: PropTypes.string,
  }),
  className: PropTypes.string,
  currency: PropTypes.string.isRequired,
  onChangeCartOpen: PropTypes.func,
  open: PropTypes.bool,
};

CartAside.defaultProps = {
  classes: undefined,
  className: undefined,
  onChangeCartOpen: undefined,
  open: false,
};

export default CartAside;
