import React from 'react';
import PropTypes from 'prop-types';
import ProductParametersInline from '@sprinx/react-com/ProductParametersInline';
import useConsolidatedParams from '@sprinx/react-products/useConsolidatedParams';

function AppCartAsideProductParameters({ product, ...rest }) {
  const parameters = useConsolidatedParams({ translationPrefix: 'com/product' }, product);
  const nextParameters = parameters.filter((i) => ['/ean', '/packaging/packSize'].includes(i.code));
  return <ProductParametersInline {...rest} parameters={nextParameters} naked />;
}

AppCartAsideProductParameters.propTypes = {
  product: PropTypes.shape({ id: PropTypes.string.isRequired }).isRequired,
};

export default AppCartAsideProductParameters;
