import { useContext } from 'react';
import invariant from 'invariant';
import ShoppingContext from '../ShoppingProvider/ShoppingContext';

export default function useShoppingCurrency() {
  const ctx = useContext(ShoppingContext);
  invariant(ctx, "Don't use 'useShoppingCurrency' outside of <ShoppingProvider>");

  return ctx.currency;
}
