import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import queryBuilder from '@sprinx/query-builder';
import App from './App';

queryBuilder.mlklSyncPreset();
// ReactDOM.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>,
//   document.getElementById('root'),
// );
ReactDOM.render(<App />, document.getElementById('root'));
