import useServiceCall from '@sprinx/react-api-client/useServiceCall';
import useDefaults from '@sprinx/react-hooks/useDefaults';
import useResolveServiceActionName from '@sprinx/react-api-client/useResolveServiceActionName';

export default function useUserSettingsServiceCallUpdate(options) {
  // const { clientCode, serviceNames } = useOrdersContext();
  const serviceName = useResolveServiceActionName(null, 'v1.settings.user' /* , serviceNames */);

  return useServiceCall(useDefaults(options, { clientCode: 'api' }), (client, params) =>
    client.call(serviceName, params, { method: 'post', mockAction: 'setuser' }),
  );
}
