import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import CartAsideSubjectsListItemDirect from '@sprinx/react-shopping-cart/CartAsideSubjectsListItemDirect';
import CartAsideSubjectsListItemName from '@sprinx/react-shopping-cart/CartAsideSubjectsListItemName';
import CartAsideSubjectsListItemPrice from '@sprinx/react-shopping-cart/CartAsideSubjectsListItemPrice';
import CartAsideSubjectsListItemParameters from '@sprinx/react-shopping-cart/CartAsideSubjectsListItemParameters';
import CartAsideSubjectsListItemProductImage from '@sprinx/react-shopping-cart/CartAsideSubjectsListItemProductImage';
import CartAsideSubjectsListItemQuantity from '@sprinx/react-shopping-cart/CartAsideSubjectsListItemQuantity';
import CartAsideSubjectsListItemActions from '@sprinx/react-shopping-cart/CartAsideSubjectsListItemActions';
import CartAsideSubjectsListItemActionsRemoveAction from '@sprinx/react-shopping-cart/CartAsideSubjectsListItemActionsRemoveAction';
import AppCartAsideProductParameters from './AppCartAsideProductParameters';

const useStyles = makeStyles({
  root: {
    '@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)': {
      display: 'flex',
      flexDirection: 'column',
    },
  },
});

function AppCartAsideSubjectsListItemDirect({ currency, subject, ...rest }) {
  const classes = useStyles();

  // !! Order of `CartAsideSubjectsListItemDirect` children is for IE purposes
  return (
    <CartAsideSubjectsListItemDirect className={classes.root} {...rest}>
      <CartAsideSubjectsListItemActions>
        <CartAsideSubjectsListItemActionsRemoveAction subject={subject} />
      </CartAsideSubjectsListItemActions>
      <CartAsideSubjectsListItemName subject={subject} />
      <CartAsideSubjectsListItemProductImage subject={subject} />
      <CartAsideSubjectsListItemParameters subject={subject} ParametersComponent={AppCartAsideProductParameters} />
      <CartAsideSubjectsListItemQuantity subject={subject} />
      <CartAsideSubjectsListItemPrice
        subjectPrice={subject.price}
        unit={subject.unit}
        subjectTotal={subject.subjectTotal}
        currency={currency}
      />
    </CartAsideSubjectsListItemDirect>
  );
}

AppCartAsideSubjectsListItemDirect.propTypes = {
  currency: PropTypes.string.isRequired,
  /**
   * @ignore
   */
  readonly: PropTypes.bool,
  subject: PropTypes.shape({
    deleted: PropTypes.bool,
    id: PropTypes.string.isRequired,
    indirect: PropTypes.bool,
    price: PropTypes.number,
    priceOriginal: PropTypes.number,
    product: PropTypes.shape({
      href: PropTypes.string,
      id: PropTypes.string,
      image: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string))]),
      name: PropTypes.array,
    }).isRequired,
    quantity: PropTypes.number,
    subjectTotal: PropTypes.number,
    unit: PropTypes.string,
  }).isRequired,
};

AppCartAsideSubjectsListItemDirect.defaultProps = {
  readonly: false,
};

export default AppCartAsideSubjectsListItemDirect;
