import React from 'react';
import useTranslate from '@sprinx/react-globalize/useTranslate';
import AppMenu from '@sprinx/react-mui-layout/AppTreeMenu';
import AppMenuItem from '@sprinx/react-mui-layout/AppTreeMenuItem';
// import ActivitiesMenuItemIcon from '@sprinx/crm-react-activities/ActivitiesMenuItemIcon';
// import ContactsMenuItemIcon from '@sprinx/crm-react-contacts/ContactsMenuItemIcon';
// import OpportunitiesMenuItemIcon from '@sprinx/crm-react-opportunities/OpportunitiesMenuItemIcon';
// import ProductsMemuItemIcon from '@sprinx/crm-react-products/ProductsMemuItemIcon';
// import { UsersIcon } from './users/icons';
import DashboardIcon from '@material-ui/icons/Dashboard';
import CatalogueIcon from '@material-ui/icons/ShoppingCart';
import OrdersIcon from '@material-ui/icons/ShoppingBasket';
import ListIcon from '@material-ui/icons/List';
// import InvoicesIcon from './@sprinx/react-invoices/icons/Invoices';
// import WarrantiesIcon from './@sprinx/react-warranty-claims/icons/Warranties';
// import ZooAppTreeMenuItemCatalogue from './_zoo/ZooAppTreeMenuItemCatalogue';

function AppNavigation(props) {
  const t = useTranslate();

  return (
    <AppMenu {...props}>
      <AppMenuItem path='/' icon={<DashboardIcon />} primary={t('globalNavigation/home')} />
      <AppMenuItem path='/catalogue' icon={<CatalogueIcon />} primary={t('globalNavigation/catalogue')} />
      {/* <ZooAppTreeMenuItemCatalogue
        path='/catalogue'
        icon={<CatalogueIcon />}
        primary={t('globalNavigation/catalogue')}
      /> */}
      <AppMenuItem path='/orders' icon={<OrdersIcon />} primary={t('globalNavigation/orders')} />
      <AppMenuItem path='/shopping-lists' icon={<ListIcon />} primary={t('globalNavigation/shoppingLists')} />
      {/* <AppMenuItem path='/invoices' icon={<InvoicesIcon />} primary={t('globalNavigation/invoices')} /> */}
      {/* <AppMenuItem path='/warranty-claims' icon={<WarrantiesIcon />} primary={t('globalNavigation/warrantyClaims')} /> */}
      {/* <AppMenuItem
        path='/contacts'
        icon={<ContactsMenuItemIcon />}
        primary={t('contacts/mainMenu/primary')}
        secondary={t('contacts/mainMenu/secondary')}
      />

      <AppMenuItem
        path='/activities'
        icon={<ActivitiesMenuItemIcon />}
        primary={t('activities/mainMenu/primary')}
        secondary={t('activities/mainMenu/secondary')}
      />

      <AppMenuItem
        path='/opportunities'
        icon={<OpportunitiesMenuItemIcon />}
        primary={t('opportunities/mainMenu/primary')}
        secondary={t('opportunities/mainMenu/secondary')}
      />

      <AppMenuItem
        path='/products'
        icon={<ProductsMemuItemIcon />}
        primary={t('products/mainMenu/primary')}
        secondary={t('products/mainMenu/secondary')}
      /> */}

      {/* <AppMenuItem icon={<UsersIcon />} path='/users' primary={t('oauthUsers/menuPrimary')} />
      <AppMenuItem icon={<UsersIcon />} path='/dynamic' primary='Dynamic' /> */}
    </AppMenu>
  );
}

export default AppNavigation;
