import React from 'react';
import PropTypes from 'prop-types';
import convertToSearchable from '@sprinx/react-globalize/convertToSearchable';
import useServiceCall from '@sprinx/react-api-client/useServiceCall'
import { termsAndConditions } from '../../termsAndConditions.config';
import ShoppingContext from './ShoppingContext';

function ShoppingProvider({
  children,
  customer,
  defaultCurrency,
  defaultNoTaxPrices,
  defaultOrderTermAndCondition,
  deliveryPlace,
}) {
  const [ctx, setCtx] = React.useState({
    currency: defaultCurrency,
    customer: undefined,
    deliveryPlace: undefined,
    deliveryPlaces: undefined,
    noTaxPrices: defaultNoTaxPrices,
    orderTermAndCondition: defaultOrderTermAndCondition,
    orderTermsAndConditionsTypes: termsAndConditions,
    orderTermsAndConditionsTypesSearchable: convertToSearchable(null, termsAndConditions, 'label'),
    orderMinimumTotal: {
      EUR: {
        amount: 100,
        amountWithTax: 120,
      },
    },
  });

  const syncState = useServiceCall(
    { returnOnlyAction: true },
    (client, params) => client.call('v1.state.sync', params || {}, { method: 'put'})
  );

  const updateContext = React.useCallback((nextCtxPartial) => {
    setCtx((ps) => ({
      ...ps,
      ...nextCtxPartial,
      currency: nextCtxPartial.currency || ps.currency,
    }));
  }, []);

  const updateDeliveryPlace = React.useCallback((nextDeliveryPlace) => {
    setCtx((ps) => ({
      ...ps,
      deliveryPlace: nextDeliveryPlace,
    }));
  }, []);

  // Props deliveryPlace or customer updated
  React.useEffect(() => {
    setCtx((p) => ({
      ...p,
      customer,
      deliveryPlace,
    }));
  }, [customer, deliveryPlace]);

  // If `ctx` customer or delivery place is changed update price group
  React.useEffect(() => {
    const { priceGroup: priceGroupToUpdate } = (ctx.deliveryPlace || ctx.customer || { priceGroup: '_default '})

    if(priceGroupToUpdate ) {
      syncState({ priceGroup: priceGroupToUpdate });
    }
  }, [ctx.customer, ctx.deliveryPlace, syncState]);

  return (
    <ShoppingContext.Provider
      value={{
        ...ctx,
        updateContext,
        updateDeliveryPlace,
      }}
    >
      {children}
    </ShoppingContext.Provider>
  );
}

ShoppingProvider.propTypes = {
  children: PropTypes.node,
  customer: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }),
  defaultCurrency: PropTypes.string.isRequired,
  defaultNoTaxPrices: PropTypes.bool.isRequired,
  defaultOrderTermAndCondition: PropTypes.string,
  deliveryPlace: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

ShoppingProvider.defaultProps = {
  children: null,
  customer: null,
  defaultOrderTermAndCondition: undefined,
  deliveryPlace: undefined,
};

export default ShoppingProvider;
