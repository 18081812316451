import React from 'react';
import PropTypes from 'prop-types';
import invariant from 'invariant';
import useEffectPreferredLocale from '@sprinx/react-globalize/useEffectPreferredLocale';
// import useApplicationSettingsServiceCallAll from './useApplicationSettingsServiceCallAll';
// import useUserSettingsServiceCallAll from './useUserSettingsServiceCallAll';
import useUserSettingsServiceCallUpdate from './useUserSettingsServiceCallUpdate';
import useEffectCurrency from './shop/useEffectCurrency';

const AppSettingsContext = React.createContext(null);
AppSettingsContext.displayName = 'AppSettingsContext';

/**
 * @component
 */
function AppSettinngsProvider({ children, defaultSettings }) {
  const [{ currency, locale }, setCtx] = React.useState(defaultSettings);
  const userApiCallUpdate = useUserSettingsServiceCallUpdate({ returnOnlyAction: true });
  const updateSettings = React.useCallback(
    (field) => (nextFieldValue) => {
      userApiCallUpdate({ [field]: nextFieldValue })
        .then((x) => {
          return x;
        })
        .then((r) => setCtx((ps) => ({ ...ps, ...r })));
    },
    [userApiCallUpdate],
  );
  // const applicationApiCall = useApplicationSettingsServiceCallAll({ returnOnlyAction: true });
  // const userApiCall = useUserSettingsServiceCallAll({ returnOnlyAction: true });

  // React.useEffect(() => {
  //   applicationApiCall()
  //     .then((r) => userApiCall().then((ru) => ({ ...r, ...ru })))
  //     .then(setCtx);
  // }, []); // eslint-disable-line react-hooks/exhaustive-deps
  useEffectPreferredLocale(locale);
  useEffectCurrency(currency);

  return (
    <AppSettingsContext.Provider
      value={{
        currency,
        locale,
        updateLocale: updateSettings('locale'),
        updateCurrency: updateSettings('currency'),
      }}
    >
      {children}
    </AppSettingsContext.Provider>
  );
}

AppSettinngsProvider.propTypes = {
  children: PropTypes.node,
  defaultSettings: PropTypes.shape({}),
};

AppSettinngsProvider.defaultProps = {
  children: null,
  defaultSettings: {},
};

export default AppSettinngsProvider;

export function useAppSettingsCurrency() {
  const ctx = React.useContext(AppSettingsContext);
  invariant(ctx, "Don't use 'useAppSettingsCurrency' outside of <AppSettinngsProvider>");

  return ctx.currency;
}

export function useAppSettingsUpdateCurrencyHandler() {
  const ctx = React.useContext(AppSettingsContext);
  invariant(ctx, "Don't use 'useAppSettingsUpdateCurrencyHandler' outside of <AppSettinngsProvider>");

  return ctx.updateCurrency;
}

export function useAppSettingsLocale() {
  const ctx = React.useContext(AppSettingsContext);
  invariant(ctx, "Don't use 'useAppSettingsLocale' outside of <AppSettinngsProvider>");

  return ctx.locale;
}

export function useAppSettingsUpdateLocaleHandler() {
  const ctx = React.useContext(AppSettingsContext);
  invariant(ctx, "Don't use 'useAppSettingsUpdateLocaleHandler' outside of <AppSettinngsProvider>");

  return ctx.updateLocale;
}
