import React from 'react';
import PropTypes from 'prop-types';
import { Switch /* , Redirect */ } from 'react-router-dom';
import PublicRoute from '@sprinx/react-routes/PublicRoute';
import PrivateRoute from '@sprinx/react-routes/PrivateRoute';
// import useRouteHistory from '@sprinx/react-routes/useRouteHistory';
import AppLayout from '@sprinx/react-mui-layout/LayoutProductionApp';
import LayoutFullArea from '@sprinx/react-mui-layout/LayoutFullArea';
import AppBarLoader from '@sprinx/react-mui-layout/AppBarLoader';
import LayoutShopping from './shop/LayoutShopping';
import useShoppingCurrency from './shop/useShoppingCurrency';

const LoginPage = React.lazy(() => import('@sprinx/react-mui-pages/LoginPage'));
const LogoutPage = React.lazy(() => import('@sprinx/react-mui-pages/LogoutPage'));
// const AppModulesSetup = lazy(() => import('./AppModulesSetup'));
//
// const DashboardPage = lazy(() => import('./Dashboard'));
// const Detail = lazy(() => import('./Detail'));
// const ClientsCrossroad = lazy(() => import('./clients/ClientsCrossroad'));
// const DynamicForm = lazy(() => import('./DynamicForm'));
// const ContactsBoilerplate = lazy(() => import('./contacts/ContactsBoilerplate'));
// const ProductsBoilerplate = lazy(() => import('./products/ProductsBoilerplate'));
// const ActivitiesCrossroad = lazy(() => import('@sprinx/crm-react-activities/ActivitiesCrossroad'));
// const OpportunitiesCrossroad = lazy(() => import('@sprinx/crm-react-opportunities/OpportunitiesCrossroad'));
// const GlobalSearch = lazy(() => import('./globalSearch/GlobalSearchPage'));
const StartPage = React.lazy(() => import('./shop/StartPage'));
// const UserProfilePage = React.lazy(() => import('./shop/UserProfilePage'));
const ProductListPage = React.lazy(() => import('./catalogue/ProductListPage'));
const CartSummaryPage = React.lazy(() => import('./cart/CartSummaryPage'));
const OrderSavedConfirmationPage = React.lazy(() => import('./orders/OrderSavedThankPage'));
const OrdersCrossRoad = React.lazy(() => import('./orders/OrdersCrossRoad'));
const InvoicesCrossRoad = React.lazy(() => import('./@sprinx/react-invoices/InvoicesCrossroad'));
const WarrantyClaimsCrossroad = React.lazy(() => import('./@sprinx/react-warranty-claims/WarrantyClaimsCrossroad'));
const ProductDetailPage = React.lazy(() => import('./products/ProductDetailPage'));
const ShoppingListsPage = React.lazy(() => import('./shopping-lists/ShoppingListsPage'));
// const WarrantyClaimsListsPage = React.lazy(() => import('./@sprinx/react-invoices/WarrantyClaimsListPage'));
const TermsAndConditionsPage = React.lazy(() => import('./_zoo/TermsAndConditionsPage'));
const AppShopFailPage = React.lazy(() => import('./AppShopFailPage'));

function AppRoutes({ onAuthenticate: handleAuthenticate }) {
  const shoppingCurrency = useShoppingCurrency();
  // const history = useRouteHistory();

  // useEffect(() => {
  //   const unlisten = history.listen((location, action) => {
  //     console.log(
  //       `The current URL is ${location.pathname}${location.search}${location.hash}`,
  //     );
  //     console.log(`The last navigation action was ${action}`);
  //   });

  //   return () => {
  //     unlisten();
  //   };
  // }, []);

  return (
    <>
      <React.Suspense fallback={<AppBarLoader />}>
        {/* <AppModulesSetup> */}
        <Switch>
          <PrivateRoute layout={AppLayout} path='/' exact component={StartPage} />
          <PrivateRoute
            layout={LayoutShopping}
            path='/catalogue/:categories*'
            render={(p) => <ProductListPage {...p} currency={shoppingCurrency} />}
          />
          <PrivateRoute layout={LayoutShopping} path='/products/:id' component={ProductDetailPage} />
          <PrivateRoute
            layout={AppLayout}
            path='/cart/summary'
            render={(p) => <CartSummaryPage {...p} currency={shoppingCurrency} />}
          />
          <PrivateRoute layout={AppLayout} path='/order-saved-confirmation' component={OrderSavedConfirmationPage} />
          <PrivateRoute layout={AppLayout} path='/orders' component={OrdersCrossRoad} />
          <PrivateRoute layout={AppLayout} path='/invoices' component={InvoicesCrossRoad} />
          <PrivateRoute layout={AppLayout} path='/warranty-claims' component={WarrantyClaimsCrossroad} />
          {/* <PrivateRoute layout={AppLayout} path='/profile' component={UserProfilePage} /> */}
          <PrivateRoute layout={AppLayout} path='/shopping-lists' component={ShoppingListsPage} />

          <PrivateRoute layout={AppLayout} path='/terms-and-coditions' component={TermsAndConditionsPage} />
          {/* <PrivateRoute
            layout={AppLayout}
            path='/'
            exact
            // render={() => <Redirect to='/clients' />}
            render={() => <DashboardPage />}
          />
          <PrivateRoute
            layout={AppLayout}
            path='/detail/:id'
            component={Detail}
          />
          <PrivateRoute
            layout={AppLayout}
            path='/dynamic'
            render={() => (
              <DynamicForm
                loader={<AppBarLoader />}
                onClose={() => { }}
                onSave={(values) => (
                  // console.log('Dynamic form values', values);
                  Promise.resolve(values)
                )}
              />
            )}
          /> */}
          {/* <PrivateRoute
            layout={AppLayout}
            path='/activities'
            component={ActivitiesCrossroad}
          />
          <PrivateRoute
            layout={AppLayout}
            path='/contacts'
            component={ContactsBoilerplate}
          />
          <PrivateRoute
            layout={AppLayout}
            path='/opportunities'
            component={OpportunitiesCrossroad}
          />
          <PrivateRoute
            layout={AppLayout}
            path='/clients'
            component={ClientsCrossroad}
          /> */}
          {/* <PrivateRoute
            layout={AppLayout}
            path='/products'
            component={ProductsBoilerplate}
          /> */}
          {/* <PrivateRoute
            layout={AppLayout}
            path='/search/:query*'
            component={GlobalSearch}
          /> */}
          <PublicRoute path='/shopFail' layout={LayoutFullArea} component={AppShopFailPage} />
          <PublicRoute
            layout={LayoutFullArea}
            path='/login'
            // render={(pp) => <LoginPageOauth {...pp} disableAutoRedirect />}
            render={(pp) => <LoginPage {...pp} onAuthenticate={handleAuthenticate} />}
          />
          <PrivateRoute layout={LayoutFullArea} path='/logout' exact component={LogoutPage} />
        </Switch>
        {/* </AppModulesSetup> */}
      </React.Suspense>
    </>
  );
}

AppRoutes.propTypes = {
  onAuthenticate: PropTypes.func,
};
AppRoutes.defaultProps = {
  onAuthenticate: undefined,
};

export default AppRoutes;
