import React from 'react';
import PropTypes from 'prop-types';
import useClient from '@sprinx/react-api-client/useClient';
import useAuthMeta from '@sprinx/react-auth/useAuthMeta';
import useRedirect from '@sprinx/react-routes/useRedirect';
import ShoppingContext from './shop/ShoppingProvider/ShoppingContext';

function AppLoadShoppingInfo({ children, registerOnAuthenticate }) {
  const client = useClient('api');
  const authMeta = useAuthMeta();
  const setRedirect = useRedirect();
  const { customer, updateContext } = React.useContext(ShoppingContext);

  const handleLoadShoppingData = async (authCtx) => {
    const { currency: ctxCurrency, user } = authCtx;

    const data = {
      deliveryPlaces: [],
      ...(await client.call(
        'v1.shops.customerShoppingProfile',
        { user },
        { method: 'post', mockAction: 'customerShoppingProfile' },
      )),
    };

    if (!data || !data.customer) {
      setRedirect('/shopFail');
      throw new Error('Not Authorized');
    }

    const partial = {
      customer: data.customer,
      currency: data.currency || ctxCurrency,
      deliveryPlaces: data.deliveryPlaces,
      ...(data.deliveryPlaces.length ? { deliveryPlace: data.deliveryPlaces[0] } : {}),
    };

    updateContext(partial);

    return {
      ...authCtx,
      meta: {
        ...authCtx.meta,
        ...partial,
      },
    };
  };

  React.useEffect(() => {
    const [f, s] = Array.isArray(registerOnAuthenticate) ? registerOnAuthenticate : [registerOnAuthenticate];
    const handler = typeof f === 'number' ? s : f;
    const order = typeof f === 'number' ? f : s;
    handler(handleLoadShoppingData, order);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    if (!customer && authMeta) {
      const { currency: crr, customer: cst, deliveryPlace, deliveryPlaces } = authMeta;

      updateContext({ customer: cst, currency: crr, deliveryPlaces, deliveryPlace });
    }
  }, [authMeta, customer, updateContext]);

  return children;
}

AppLoadShoppingInfo.propTypes = {
  children: PropTypes.node,

  /**
   * Registrace handleru do `Authenticate` auth pipeline.
   * Vstupem je registracni metoda se signaturou ``
   *
   * Signatura hanlder metody: `(ctx) => Promise<ctx>`
   */
  registerOnAuthenticate: PropTypes.oneOfType([PropTypes.array, PropTypes.func]).isRequired,
};

AppLoadShoppingInfo.defaultProps = {
  children: null,
};

export default AppLoadShoppingInfo;
