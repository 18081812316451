import React from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';
import AppContainer from '@sprinx/react-mui-layout/AppContainer';
import ProductionAppRoot from '@sprinx/react-mui-layout/ProductionAppRoot';
import AuthProvider from '@sprinx/react-auth/AuthProvider';
import useRedirect from '@sprinx/react-routes/useRedirect';
import useRouteLocation from '@sprinx/react-routes/useRouteLocation';
// import AuthHandlerSprinxOauth from '@sprinx/react-auth/AuthHandlerSprinxOauth';
// import LayoutErrorBoundary from '@sprinx/react-mui-layout/LayoutErrorBoundary';
import LayoutProvider from '@sprinx/react-mui-layout/LayoutProvider';
// import GlobalizeContext from '@sprinx/react-globalize/GlobalizeContext';
// import CacheProvider from '@sprinx/react-cache/CacheProvider';
// import AppCache from '@sprinx/react-cache/AppCache';
// import Cache from '@sprinx/react-cache/Cache';
import MuiComponentsProvider from '@sprinx/react-mui-components/MuiComponentsProvider';
import MuiFieldsProvider from '@sprinx/react-mui-fields/MuiFieldsProvider';
import { create as createJss } from 'jss';
import { StylesProvider, jssPreset } from '@material-ui/core/styles';
// import AppConnectApis from './AppConnectApis';
import AppSettingsProvider from './AppSettingsProvider';
import AppGlobalize from './AppGlobalize';
import AppShowIf from './AppShowIf';
import AppMenu from './AppMenu';
// import AppMenuSecondary from './AppMenuSecondary';
import AppBarActions from './AppBarActions';
import AppRoutes from './AppRoutes';
import ShoppingProvider from './shop/ShoppingProvider';
// import CommerceBoilerplate from './CommerceBoilerplate';
import AppConnectServers from './AppConnectServers';
import AppAuthHandler from './AppAuthHandler';
import AppLoadShoppingInfo from './AppLoadShoppingInfo';
import applicationSettings from './applicationSettings';

const CommerceBoilerplate = React.lazy(() => import('./CommerceBoilerplate'));

const applicationTitle = 'Virtual Rep';

const displayName = 'App';
const propTypes = {};
const defaultProps = {};

const theme = {
  palette: {
    primary: {
      // light: '#3399cc',
      main: '#003DA7',
      // dark: '#000099',
      // contrastText: '#fff',
    },
    secondary: {
      // light: '',
      main: '#ffca11',
      // contrastText: '',
    },
  },
  typography: {
    h1: {
      fontSize: '4rem',
      fontWeight: 400,
      margin: '16px 0 24px 0',
    },
  },
};

const appJss = createJss({ plugins: jssPreset().plugins });

function App() {
  return (
    <StylesProvider jss={appJss}>
      <AppContainer
        defaultTitle={applicationTitle}
        RootComponent={ProductionAppRoot}
        ErrorComponent={() => <h1>Something went wrong.</h1>}
        themeSettings={theme}
      >
        <AuthProvider
          useLocation={useRouteLocation}
          useRedirect={useRedirect}
          render={({ accessToken, authenticate, registerOnAuthenticate, registerOnLogout }) => (
            <AppConnectServers
              accessToken={accessToken}
              registerOnAuthenticate={registerOnAuthenticate}
              registerOnLogout={registerOnLogout}
            >
              <AppAuthHandler registerOnAuthenticate={registerOnAuthenticate} registerOnLogout={registerOnLogout} />
              <AppGlobalize
                supportedLocales={applicationSettings.supportedLocales}
                defaultLocale={applicationSettings.locale}
              >
                <ShoppingProvider
                  defaultCurrency={process.env.REACT_APP_SX_DEFAULT_CURRENCY || applicationSettings.currency || 'EUR'}
                  defaultNoTaxPrices={!applicationSettings.pricesWithTaxes}
                >
                  <AppSettingsProvider defaultSettings={applicationSettings}>
                    <AppShowIf>
                      <AppLoadShoppingInfo
                        registerOnAuthenticate={registerOnAuthenticate}
                        registerOnLogout={registerOnLogout}
                      />
                      <MuiComponentsProvider
                        currencyTypes={applicationSettings.currencyTypes}
                        linkComponent={ReactRouterLink}
                        // googleApiKey='AIzaSyCXMK2rOHbgaT74tr82E9hlguojl7yCWpo'
                        supportedLanguages={applicationSettings.supportedLocales}
                      >
                        <MuiFieldsProvider
                          currencyTypes={applicationSettings.currencyTypes}
                          supportedLanguages={applicationSettings.supportedLocales}
                        >
                          <LayoutProvider
                            appBarActions={AppBarActions}
                            appMenu={AppMenu}
                            // secondaryAppMenu={AppMenuSecondary}
                            title={applicationTitle}
                          >
                            <CommerceBoilerplate>
                              <AppRoutes onAuthenticate={authenticate} />
                            </CommerceBoilerplate>
                          </LayoutProvider>
                        </MuiFieldsProvider>
                      </MuiComponentsProvider>
                    </AppShowIf>
                  </AppSettingsProvider>
                </ShoppingProvider>
              </AppGlobalize>
            </AppConnectServers>
          )}
        />
      </AppContainer>
    </StylesProvider>
  );
}

App.displayName = displayName;
App.propTypes = propTypes;
App.defaultProps = defaultProps;

export default App;
