import React from 'react';
import PropTypes from 'prop-types';
import { pickHTMLProps } from 'pick-react-known-prop';
import makeStyles from '@material-ui/core/styles/makeStyles';
import ToolbarAction from '@sprinx/react-mui-components/ToolbarAction';
import SaveIcon from '@material-ui/icons/Save';
import ImportIcon from 'mdi-material-ui/CartArrowDown';
import useTranslate from '@sprinx/react-globalize/useTranslate';
import useShoppingListsActions from '@sprinx/react-shopping-lists/useShoppingListsActions';
import useCartContent from '@sprinx/react-shopping-cart/useShoppingCartContent';

const useStyles = makeStyles(
  () => ({
    root: {
      flexGrow: 0,
      width: '100%',
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
    },
  }),
  { name: 'CartTools' },
);

function CartTools({ classes: pClasses, className, ...rest }) {
  const classes = useStyles({ classes: pClasses, className });
  const t = useTranslate();
  const { openSaveAsShoppingListDialog, openShoppingListsDialog } = useShoppingListsActions();
  const [cart] = useCartContent();
  return (
    <div {...pickHTMLProps(rest)} className={classes.root}>
      <ToolbarAction
        color='default'
        variant='standalone'
        icon={<SaveIcon />}
        label={t('shoppingLists/actions/saveAsShoppingList')}
        onClick={openSaveAsShoppingListDialog(cart)}
        disabled={cart.subjects.length === 0}
      />
      <ToolbarAction
        color='default'
        variant='standalone'
        icon={<ImportIcon />}
        label={t('shoppingLists/actions/importShoppingListToCart')}
        onClick={openShoppingListsDialog}
      />
    </div>
  );
}

CartTools.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string,
  }),
  className: PropTypes.string,
};

CartTools.defaultProps = {
  classes: undefined,
  className: undefined,
};

export default CartTools;
