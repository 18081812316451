import React from 'react';
import invariant from 'invariant';
import ShoppingContext from '../ShoppingProvider/ShoppingContext';

export default function useShoppingChangeCurrencyHandler() {
  const ctx = React.useContext(ShoppingContext);
  invariant(ctx, "Don't use 'useShoppingChangeCurrencyHandler' outside of <ShoppingProvider>");

  const { updateContext } = ctx;
  return React.useCallback(
    (nextCurrency) => {
      updateContext({ currency: nextCurrency });
    },
    [updateContext],
  );
}
