import React from 'react';
import useShoppingChangeCurrencyHandler from '../useShoppingChangeCurrencyHandler';

export default function useEffectCurrency(currency) {
  const handleChangeCurrency = useShoppingChangeCurrencyHandler();

  React.useEffect(() => {
    handleChangeCurrency(currency);
  }, [currency, handleChangeCurrency]);
}
