import React from 'react';
import PropTypes from 'prop-types';
// import useAppCache from '@sprinx/react-cache/useAppCache';
import GlobalizeProvider from '@sprinx/react-globalize/GlobalizeProvider';
// import useOnAuthenticated from '@sprinx/react-auth/useOnAuthenticated';
// import useServiceCall from '@sprinx/react-api-client/useServiceCall';

// CLDRS
const CldrLocaleEn = React.lazy(() => import('@sprinx/react-globalize/CldrLocaleEn'));
const CldrLocaleSk = React.lazy(() => import('@sprinx/react-globalize/CldrLocaleSk'));
// Messages
const MessagesEn = React.lazy(() => import('./MessagesEn'));
const MessagesSk = React.lazy(() => import('./MessagesSk'));

function AppGlobalize({ children, defaultLocale, onPreferredLanguageChanged, supportedLocales }) {
  // Load language call
  // const loadLanguageSetting = useServiceCall({ returnOnlyAction: true }, (client) => client.call('v1.language'));

  // const cache = useAppCache();

  // const onAuth = useOnAuthenticated();

  return (
    <GlobalizeProvider
      cldrs={{ en: CldrLocaleEn, sk: CldrLocaleSk }}
      messages={{ en: MessagesEn, sk: MessagesSk }}
      defaultLocale={defaultLocale}
      supportedLocales={supportedLocales}
      onPreferredLanguageChanged={onPreferredLanguageChanged}
      dontResolveLocale
      // defferInitialization={onAuth}
      // cache={cache}
      // loadSetting={loadLanguageSetting}
      // onPreferredLanguageChanged={onPreferredLanguageChanged}
    >
      {children}
    </GlobalizeProvider>
  );
}

AppGlobalize.propTypes = {
  children: PropTypes.node.isRequired,

  /**
   * Defaultni jazyk.
   */
  defaultLocale: PropTypes.string,

  /**
   * Handler zmeny preferovaneho jazuku.
   *
   * Signatura: `(languageCode) => void`
   */
  onPreferredLanguageChanged: PropTypes.func,

  supportedLocales: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        code: PropTypes.string.isRequired,
      }),
    ]),
  ).isRequired,
};

AppGlobalize.defaultProps = {
  defaultLocale: 'en',
  onPreferredLanguageChanged: undefined,
};

export default AppGlobalize;
