const applicationSettings = {
  currency: 'EUR',
  currencyTypes: ['EUR'],
  locale: 'sk',
  supportedLocales: [
    // { code: 'cs', label: 'Česky' },
    { code: 'en', label: 'English' },
    { code: 'sk', label: 'Slovelnsky' },
    // { code: 'pl', label: 'Polska' },
  ],
  pricesWithTaxes: false,
};

export default applicationSettings;
